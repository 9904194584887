import * as React from "react"
import Layout from "../components/common/layout"
import Title from "../components/title"
import { graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import SubMenu from "../components/common/sub-menu"

const Feedback = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  return (
    <Layout>
      <Helmet
          title='Help desk'
        />
         <SubMenu data={subMenu.data[0]}></SubMenu>
        <Title data={dataTitle.data[0]} background="bg-white" ></Title>
        <div className="form-bblock">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-4">
                    {/* emeb form here */}
                    <div className="mb-20">
                        <label>First name</label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="mb-20">
                        <label>Last name</label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="mb-20">
                        <label>Email</label>
                        <input type="email" className="form-control" />
                    </div>
                    <div className="mb-20">
                        <label>Are you a member?</label>
                        <select className="form-control" >
                            <option>Please choose</option>
                        </select>
                    </div>
                    <div className="mb-20">
                        <label>Feedback or suggestion / idea?</label>
                        <select className="form-control" >
                            <option>Please choose</option>
                        </select>
                    </div>
                    <div className="mb-20">
                        <label>Subject</label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="mb-20">
                        <label>Message</label>
                        <textarea  className="form-control" rows={7}>
                        This is a sample long message that wraps across multiple lines for formatting purposes.
                        </textarea>
                    </div>
                    <div className="action-form">
                        <button type="submit" className="btn btn-primary d-block">Submit</button>
                    </div>
                     {/* emeb form here */}
                </div>
            </div>
        </div>
        </div>
    </Layout>
  )
} 

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["feedback"]}}) {
      nodes {
        jsonName
        data {
          block
            data {
              sub_menu{
                title
                links {
                  id
                  text
                  link
                  active
                }
              }
              title_data {
                title
                content
                links{
                  id
                  text
                  url
                }
              }
            }
        }
      }
    }
  }
`
export default Feedback
